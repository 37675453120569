import type { HeroType } from '../../Hero';

export const heroBackgroundFallback = (heroType: HeroType) => {
  if (heroType === 'DiscoverWeeklyHero') {
    return {
      image: {
        url: '/static/images/hero-backgrounds/11-discover-weekly-desktop.jpg',
      },
      mobileImage: {
        url: '/static/images/hero-backgrounds/11-discover-weekly-mobile.jpg',
      },
    };
  }

  return {
    image: {
      url: '/static/images/hero-backgrounds/11-basic-desktop.jpg',
    },
    mobileImage: {
      url: '/static/images/hero-backgrounds/11-basic-mobile.jpg',
    },
  };
};
